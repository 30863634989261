import React, { useContext, useEffect, useState } from 'react';
import GoBackLink from '../components/go-back-link';
import LinkWithPageTransition from '../components/link-with-page-transition';
import Meta from '../components/meta';
import ModulesController from '../components/modules-controller';
import PlusIcon from '../components/svg/plus-icon';
import LokaltogContext from '../context/context';
import { useContent } from '../hooks/use-content';
import { PageData } from '../types/global.types';
import { INewsLibraryPage } from '../types/heartcore.types';
import { htmlRaw } from '../utils/utils';

const ArticlesLibraryPage = ({pageContext}:PageData<INewsLibraryPage>) => {
	const context = useContext(LokaltogContext);
	const { meta, parent, modules, headline, bodyText, children, pageSize: origPageSize } = useContent(pageContext, context.rootNodeUrl);
	const [paginatedArticles, setPaginatedArticles ] = useState(null);
	const [currentPage, setCurrentPage ] = useState(0);
	const [pageSize, setPageSize ] = useState(null);

	const loadMoreHandler = () => {
		setCurrentPage(prev => ++prev);
	};

	useEffect(() => {
		if(!children) { return; }
		const currentStart = currentPage * pageSize;

		if(!pageSize) {
			setPaginatedArticles(children);
			return;
		}

		if(!paginatedArticles) {
			setPaginatedArticles(children.slice(0, pageSize));
		} else {
			setPaginatedArticles(prev => {
				const add =  children.slice(currentStart, currentStart + pageSize);
				return [...prev, ...add];
			});

		}
	}, [currentPage, children, pageSize]);

	useEffect(() => {
		setPageSize(origPageSize ? origPageSize : null);
	}, []);

	return (
		<>
			<Meta {...meta} />
			<div className='news-library-page'>
				<div className='box-wide'>
					<GoBackLink parent={parent}></GoBackLink>
					<div className='news-library-page__content'>
						<h1>{headline}</h1>
						<p>{bodyText}</p>
					</div>
					<div className='news-library-page__articles'>
						{paginatedArticles?.map(article => 
							<section className='news-library-page__article' key={article.id} id={article.id}>
								<LinkWithPageTransition url={article.url}>
									<h3 className='headline-with-html' dangerouslySetInnerHTML={htmlRaw(article.headline)}></h3>
									<p>{article.introText}</p>
									<span className='link'>{context.tr('Global.ReadMore')}</span>
								</LinkWithPageTransition>
							</section>,
						)}
						{children?.length <= pageSize || children?.length === paginatedArticles?.length 
							?
							null
							:
							<button className='news-library-page__load-more-button' onClick={loadMoreHandler}>
								<PlusIcon />
								<div className='link'>{context.tr('Global.SeeMore')}</div>
							</button>
						}
					</div>
				</div>
				<ModulesController modules={modules} />
			</div>
		</>
	);
};

export default ArticlesLibraryPage;
